@import "./variables";

#offer {
    background-size: cover;
    color: $colorText2;
    padding-top: 30px;
    padding-bottom: 40px;
}

#offer .container {
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#offer .offerTitleHolder {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#offer .offerTitle {
    width: 50%;
    font-size: 40px;
    font-weight: lighter;
    text-align: center;
    color: $colorLight1;
}

#offer .offerList {
    height: 80%;
    display: flex;
    justify-content: space-between;
}

#offer .offerList .offerItem {
    position: relative;
    height: 600px;
    width: 31%;
    background: $colorSecondary1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
}

#offer .offerList .offerItem .offerItemDetails {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background: $bgColor;
    border: 3px solid $colorSecondary1;
    text-align: center;
    visibility: hidden;
    opacity: 0;
    color: $bgColorAlt;
    transition: 0.5s ease-out all;
}

#offer .offerList .offerItem .offerItemDetails--active {
    visibility: visible;
    opacity: 1;
    transition: 0.5s ease-in all;
}

#offer .offerList .offerItemDetails img {
    max-height: 160px;
    max-width: 90%;
    border-radius: 10px;
    margin-bottom: 10px;
    margin-top: 15px;
}

#offer .offerList .offerItemDetails .offerItemDetailsCloseBtn {
    margin: 15px 0 30px;
    cursor: pointer;
}

#offer .offerList .offerItemDetails .offerItemDetailsText {
    padding: 10px 20px;
    font-size: 15px;
}

#offer .offerList .offerItemDetails .offerItemDetailsTextItem {
    display: flex;
    align-items: center;
    border-bottom: 1px dashed $colorPrimary2;
    padding: 5px 0;
    text-align: left;
    font-size: 13px;
}

#offer .offerList .offerItemDetails .offerItemDetailsTextItem--main {
    align-items: flex-start;
    min-height: 90px;
    padding-bottom: 8px;
    font-size: 15px;
}

#offer .offerList .offerItem .offerInfo {
    height: 50%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 0 10px;
}

#offer .offerList .offerInfoTitleHolder {
    height: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#offer .offerList .offerInfoTitleHolder .offerInfoTitle {
    font-size: 40px;
    font-weight: lighter;
    text-align: center;
}

#offer .offerList .offerInfoText {
    height: 55%;
    font-weight: lighter;
    text-align: center;
}

#offer .offerList .offerItem .offerInfoMore {
    height: 50%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 0 10px;
}

#offer .offerList .offerInfoImgHolder {
    margin-top: 10px;
    height: 70%;
    width: 95%;;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}

#offer .offerList .offerInfoImg {
    max-width: 100%;
    max-height: 100%;
}

#offer .offerList button {
    font-family: 'Nunito', sans-serif;
    width: 240px;
    height: 50px;
    margin: 20px 0 10px 0;
    background: $colorPrimary2;
    font-size: 20px;
    border: 0;
    border-radius: 5px;
    color: $bgColorAlt;
    cursor: pointer;
}

#offer .offerList button:hover {
    background: $colorPrimary1;
}

@media (max-width: 1050px) {

    #offer {
        height: auto;
    }

    #offer .container {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    #offer .offerList {
        height: 95%;
        flex-wrap: wrap;
        justify-content: center;
        margin: 15px 0;
    }

    #offer .offerList .offerItem {
        height: 32%;
        //min-height: 375px;
        width: 99%;
        flex-direction: row;
        margin: 10px 0;
    }

    #offer .offerList .offerItem .offerInfo {
        height: 95%;
        width: 50%;
        justify-content: center;
    }

    #offer .offerList .offerItem .offerInfoTitleHolder {
        padding-top: 10px;
        height: 40%;
    }

    #offer .offerList .offerItem .offerInfoText {
        padding-top: 10px;
    }

    #offer .offerList .offerItem .offerInfoMore {
        height: 95%;
        width: 50%;
    }

    #offer .offerList .offerItem .offerInfoMore .offerInfoImgHolder {
        margin-top: 0;
        align-items: center;
        height: 90%;
    }

    #offer .offerList .offerItemDetails .offerItemDetailsTextItem--main {
        min-height: 45px;
    }

    #offer .offerList .offerItemDetails img {
        display: none;
    }
}

@media (max-width: 750px) {

    #offer .offerList .offerItem {
        min-height: 375px;
    }

    #offer .offerList .offerItem .offerInfoTitle {
        font-size: 30px;
    }

    #offer .offerList .offerItem .offerInfoText {
        font-size: 20px;
    }

    #offer .offerList button {
        margin-top: 30px;
        width: 160px;
        height: 60px;
    }

    #offer .offerList .offerItemDetails .offerItemDetailsTextItem--main {
        min-height: 45px;
    }

    #offer .offerList .offerItemDetails img {
        display: none;
    }
}

@media (max-width: 600px) {

    #offer .offerList .offerItem {
        flex-direction: column;
    }

    #offer .offerList .offerItem .offerInfo {
        width: 100%;
        height: auto;
        justify-content: center;
        margin-bottom: 15px;
    }

    #offer .offerList .offerItem .decor {
        margin-bottom: 15px;
    }

    #offer .offerList .offerItem .offerInfoText {
        padding-top: 0;
        text-align: center;
    }

    #offer .offerList .offerItem .offerInfoMore {
        width: 100%;
        height: auto;
    }

    #offer .offerList .offerItemDetails img {
        display: none;
    }

    #offer .offerList .offerItemDetails .offerItemDetailsCloseBtn {
        margin-bottom: 18px;
    }
}
