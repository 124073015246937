@import "./variables";

#home {
    padding-bottom: 70px;

    .homeInfo {
        width: 100%;
        display: flex;
        align-items: center;

        .homeInfoText {
            padding-top: 20px;
            padding-bottom: 30px;
            text-align: center;
            font-size: 40px;
            font-weight: lighter;
            line-height: 60px;
            color: $colorLight1;
        }
    }

    .homeImageHolder {
        width: 100%;
        text-align: center;

        img {
            height: auto;
            width: 80%;
        }
    }
}

@media(max-width: 1150px) {

    #home .homeInfo .homeInfoText {
        font-size: 35px;
    }
}

@media(max-width: 1040px) {

    #home .homeInfo .homeInfoText {
        font-size: 30px;
    }
}

@media(max-width: 850px) {

    #home .homeInfo .homeInfoText {
        font-size: 25px;
        line-height: 38px;
    }

    #home .homeImageHolder img {
        width: 100%
    }
}

@media(max-width: 500px) {

    #home .homeInfo .homeInfoText {
        font-size: 20px;
        line-height: 30px;
    }

    #home .homeImageHolder img {
        width: 100%
    }
}
