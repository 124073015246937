@import "./variables";

#footer {
    height: 50px;
    width: 100%;
    background: $colorPrimary2;
}

#footer .container {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#footer .container .contactInfo {
    width: 40%;
}

#footer .container .contactInfo a {
    color: $colorText2;
    padding: 5px 5px;
    margin: 0 10px;
}

#footer .socialMedia {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

#footer .socialMedia a {
    padding: 0;
    margin: 5px 15px 0 0;
}

#footer .socialMedia img {
    height: 35px;
    width: 35px;
    border-radius: 10px;
}

#footer .copyright {
    text-align: right;
    font-size: 14px;
    color: $bgColor;
}
