//Background
$bgColor: #000000;
$bgColorLighter: #333333;
$bgColorAlt: #FFFFFF;

//Palette One
$colorPrimary1: #2DC9C9;
$colorSecondary1: #00A4A4;
//$colorText1: #002525;
$colorText1: #97DFDF;
$colorLight1: #97DFDF;

//Palette Two
$colorPrimary2: #DE32AA;
$colorSecondary2: #C7008B;
$colorText2: #2F0021;
$colorLight2: #EB9FD4;

//Palette Three
$colorPrimary3: #C5F838;
$colorSecondary3: #B2F300;
$colorText3: #2B3B00;
$colorLight3: #E5FBAA;

//Palette Four
$colorPrimary4: #FF9339;
$colorSecondary4: #FF7400;
$colorText4: #3E1C00;
$colorLight4: #FFD2AD;