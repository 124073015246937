@import "./variables";

#contact {
    height: calc(100vh - 120px);
    color: $colorLight1;
    padding-top: 30px;
}

#contact .container {
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#contact .contactImageHolder {
    height: 100%;
    width: 48%;
    background-image: url("../images/monika2.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    box-shadow: 0 0 20px 20px $bgColor inset;
}

#contact .contactInfo {
    height: 100%;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#contact .contactInfo .contactInfoTitle {
    font-size: 40px;
    font-weight: lighter;
    text-align: center;
}

#contact .contactInfo .contactForm {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    line-height: 32px;
}

#contact .contactInfo .contactForm .inputHolder {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 15px 0;
    font-style: italic;
}

#contact .contactInfo .contactForm input {
    height: 22px;
    width: 90%;
    background: $bgColor;
    border: 0;
    border-bottom: 1px solid $colorLight1;
    font-family: 'Quicksand', sans-serif;
    font-size: 18px;
    text-align: center;
    color: $colorLight2;
}

#contact .contactInfo .contactForm input::placeholder {
    font-size: 18px;
    font-style: italic;
    text-align: center;
}

#contact .contactInfo .contactForm input:focus,
#contact .contactInfo .contactForm textarea:focus {
    outline: $colorLight1;
}

#contact .contactInfo .contactForm textarea#message {
    width: 90%;
    height: 200px;
    background: $bgColor;
    font-family: 'Quicksand', sans-serif;
    font-size: 18px;
    color: $colorLight2;
    border: 1px solid $colorLight1;
    padding: 5px 10px;
    resize: none;
    text-align: center;
}

#contact button {
    font-family: 'Nunito', sans-serif;
    width: 160px;
    height: 50px;
    margin: 20px 0 10px 0;
    background: $colorPrimary2;
    font-size: 20px;
    border: 0;
    border-radius: 5px;
    color: $bgColorAlt;
    cursor: pointer;
}

#contact button:hover,
#contact button:focus {
    background: $colorPrimary1;
}

@media (max-width: 835px) {

    #contact {
        height: auto;
    }

    #contact .container {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
    }

    #contact .contactImageHolder {
        height: 400px;
        width: 100%;
    }

    #contact .contactInfo {
        width: 100%;
        height: auto;
        margin: 20px 0 10px 0;
    }
}

@media (max-width: 380px) {

    #contact .contactInfo .contactInfoTitle {
        font-size: 35px;
    }
}
