@import "./variables";

#about {
    width: 100%;
    padding-top: 30px;
    padding-bottom: 40px;

    .container {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .aboutInfo {
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding-right: 20px;

        .aboutInfoTitleHolder {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        .aboutInfoTitle {
            font-size: 40px;
            font-weight: lighter;
            text-align: center;
        }

        .aboutInfoText {
            width: 100%;
            line-height: 24px;
            text-align: justify;
            font-size: 18px;
            color: $colorLight1;

            p {
                margin-bottom: 10px;
            }

            p.highlightedText {
                color: $colorPrimary2;
            }

            div {
                text-align: center;
            }

            .aboutInfoTextList {
                display: inline-block;
                margin-left: 20px;
                text-align: left;
            }

            .certifiedLogo {
                margin-top: 10px;
                width: auto;
                height: 120px;
            }
        }
    }

    .aboutImageHolder {
        width: 50%;
        box-shadow: 0 0 20px 20px $bgColor inset;
        position: relative;

        img {
            position: relative;
            width: 100%;
            height: auto;
            z-index: -1;
        }
    }
}

@media (max-width: 1040px) {

    #about .container {
        flex-direction: column;
        justify-content: center;
    }

    #about .aboutInfo {
        height: auto;
        width: 100%;
        padding-right: 0;
        margin-bottom: 20px;
    }

    #about .aboutInfo .aboutInfoTitleHolder {
        height: 30%;
    }

    #about .aboutImageHolder {
        display: none;
    }
}

@media (max-width: 450px) {

    #about .aboutInfo .aboutInfoText {
        text-align: center;
    }
}
