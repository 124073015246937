@import "./variables";

#header {
    width: 100%;
    height: 70px;
    background: $bgColor;
    border-bottom: 1px solid #C8C8C9;
    position: fixed;
    top: 0;
    z-index: 3000;
}

#header .container {
    height: 100%;
    display: flex;
    justify-content: space-between;
}

#header .logoContainer {
    width: 40%;
    height: 100%;
}

#header .logo {
    width: 100%;
    height: 100%;
}

#header .logo a {
    height: 100%;
    display: flex;
    align-items: center;
}

#header .logo img {
    max-width: 100%;
    max-height: 80%;
}

#header .menuContainer {
    width: 60%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

#header .menu {
    height: 100%;
    list-style-type: none;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    z-index: 999;
}

#header .menu .menuItem {
    display: inline-block;
    border-left: 1px dashed $colorLight1;
}

#header .menu .menuItem:first-of-type {
    border-left: 0;
}

#header .menu .menuItem a {
    color: $colorLight1;
    padding: 10px;
}

#header .menu .menuItem a:hover {
    color: $colorPrimary2;
}

#header .hamburgerMenuIcon {
    display: none;
    cursor: pointer;
    margin-right: 5%;
    margin-top: -15px;
}

#header .bar1, #header .bar2, #header .bar3 {
    width: 35px;
    height: 5px;
    background-color: $colorLight1;
    margin: 6px 0;
    transition: 0.4s;
}

#header .change .bar1 {
    -webkit-transform: rotate(-45deg) translate(-7px, 8px);
    transform: rotate(-45deg) translate(-7px, 8px);
}

#header .change .bar2 {opacity: 0;}

#header .change .bar3 {
    -webkit-transform: rotate(45deg) translate(-8px, -8px);
    transform: rotate(45deg) translate(-8px, -8px);
}

@media(min-width: 1041px) {

    #header .menu {
        display: flex;
    }
}

@media(max-width: 1040px) {

    #header .logoContainer {
        width: 70%;
    }

    #header .menuContainer {
        width: 30%;
    }

    #header .menu {
        height: auto;
        margin-top: 70px;
        visibility: hidden;
        flex-direction: column;
        align-items: flex-end;
        transform: translateX(300px);
        z-index: -1;
        transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s, z-index 0s linear 0s;
    }

    #header .menu.visible {
        visibility: visible;
        opacity: 1;
        z-index: 1;
        transform: translateX(0%);
        transition-delay: 0s, 0s, 0.3s;
    }

    #header .menu .menuItem {
        width: 150px;
        height: 40px;
        display: flex;
        align-items: center;
        background: $bgColor;
        border-bottom: 1px dashed $colorLight1;
        border-left: 0;
        text-align: right;
    }

    #header .menu .menuItem a {
        width: 100%;
        text-align: center;
    }

    #header .hamburgerMenuIcon {
        display: inline-block;
        position: absolute;
        top: 30px;
        right: 0;
        cursor: pointer;
    }
}
