@import "./variables";

#gallery {
    background-size: cover;
    color: $colorText2;
    padding-top: 30px;
    padding-bottom: 40px;
}

#gallery .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.galleryTitleHolder {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.galleryTitle {
    width: 50%;
    font-size: 40px;
    font-weight: lighter;
    text-align: center;
    color: $colorLight1;
}

.wrapper {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    grid-template-rows: auto;
    grid-gap: 18px;
    justify-items: center;
}

.item {
    width: 200px;
    height: 250px;
    display: inline-block;
}

.item:hover {
    filter: none;
    transform: scale(1.03, 1.03) !important;
    transition: all 0.35s;
    cursor: pointer;
    color: $colorText1;
}

.polaroid {
    width: 100%;
    height: 250px;
    margin-bottom: 45px;
    background: #fff;
    box-shadow: 0 0.4rem 2rem rgba(0,0,0,0.5);
}

.polaroid > img {
    width: 100%;
    height: 150px;
    object-fit: cover;
    object-position: center;
}

.caption {
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    font-size: 20px;
    text-align: center;
}

.caption span {
    color: $colorPrimary2
}
